body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Añadido para sombreados de botones y hovers */
.textDarkToogle {
  color: hsl(228, 34%, 66%)
}

.textDarkToogle:hover {
  color: hsl(0, 0%, 100%)
}

.textLightToogle {
  color: hsl(228, 12%, 44%)
}


.buttonDark {
  background-color: hsl(228, 28%, 20%);
}
.buttonDark:hover {
  background: hsl(228, 28%, 25%);
}

.buttonLight {
  background: hsl(227, 47%, 96%);
}
.buttonLight:hover {
  background: hsl(227, 47%, 91%);
}

input[type="checkbox"] {
background-color: hsl(17, 86%, 59%);
}

input[type="checkbox"]:checked {
background-color: hsl(17, 86%, 59%);
}
