
#sidebar {
  width: 18%; 
  position: fixed; 
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: rgb(255, 255, 255);
  color: hsl(228, 12%, 44%);
  font-weight: 600;
  font-size: 15px;
  transition: all 0.3s;
  opacity: 1;
  box-shadow: 0 3px 16px 0 rgba(90, 90, 90, 0.2);
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/hotels-prod-a5b59.appspot.com/o/Images%2Fbackground-login.png?alt=media&token=1d4690cf-c0fe-45c1-b7de-c2c62280d047");
  background-repeat: no-repeat;
  background-size: cover; */
  /* border: 3px solid #ff2068; */
}


/* TODO: ERR_TUTORIA => lo que me gustaria es que mostrase el sidebar o el topbar en funcion del tamaño de pantalla */
/* @media (max-width: 768px) { */
@media (max-width: 940) {
  #sidebar {
    display: none;
    /* margin-left: -250px; */
  }

  #sidebar.active {
    margin-left: 0;
  }
  
}


body {
  font-family: "InterBold", sans-serif;
  background: #ffffff;
}

p {
  font-family: "InterBold", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar  ul li a:hover {
  color: white;
  background: #4dc2f1;
}




