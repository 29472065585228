




._buttonFloat {
    position: absolute;
    bottom: 4%;
    right: -7%;
    display: flex;
    justify-content: flex-end;
    height: 250px;
}

._btnGif {
    position: absolute;
    bottom: 4%;
    right: -100;
    display: flex;
    justify-content: flex-end;
    height: 250px;
}

._columnImage {
    display: flex;
    height: 100vh;
    width: 50vw;
    margin: 0;
    padding: 0;
    /* background-image: `url("https://i.postimg.cc/pr1b0Vf9/login-image.jpg")`; */
    /* background-image: url("../img/login-image.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
}

._columnInputs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    margin: 0;
    padding: 0;
}

._container {
    /* position: fixed; */
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    margin: 0;
    padding: 0;
    background-color: #fff;
    /* backgroundColor: #fff */
}

._containerHome {
    /* position: fixed; */
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    /*padding: `${screenSize > 940 ? ("3% 2% 3% 20%") : ("30px")}`;*/
    padding: 3% 2% 3% 280px;
    height: 100%;
    overflow: auto;
}

._containerLoaderHome {
    display: flex;
    /* alignItem: 'center', */
    align-self: center;
    justify-content: center;
    height: 80%;
}

._containerLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    height:75vh;
    width: 100%;
}

._iconDiv {
    width: 35%;
    display: flex;
    align-items: left;
}

._icons {
    display: flex;
    align-items: center;
}

._imageLogo {
    height: 10%;
    width: auto;
    position: relative;
    left: 10%;
    top: 80%;
}

._form {
    width: 100%;
    padding: 8%;
    margin-right: 2.5%;
}

._formButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4DC2F1;
    border-radius: 25;
    border: none;
    padding: 8px 32px;
    margin: 35px 0px 0px;
    color: #fff;
    font-weight: 500;
    outline: 0;
    width: 130;
    height: 40;
}

._formContainer {
    /* width: `${screenSize > 940 ? ("50vw") : ("100vw")}`; aqui el de mayor y en media el de menor*/
    /* width: "###SCREENSIZE### > 940 ? (" 50vw"): (" 100vw")"; */
    width: 50vw;
    margin-top: 1%;
    padding: 1;
}

._formInput {
    margin-bottom: 20;
    font-family: "InterRegular";
}

._formLabel {
    margin-bottom: 10;
    font-family: "InterRegular";
    background-color: red;
}

._formTitle {
    font-size: 40px; 
     margin-bottom: 50;
}

._lineDetailCenter {
    display: flex;
    align-items: right;
    justify-content: space-between;
    margin: 12px 15px;
}

._linkHome {
    padding: 15;
    display: flex;
    align-items: center;
}

._loader {
    padding: 2em;
    display: flex;
    align-self: center;
}

._loaderHome {
    display: flex;
    align-self: center;
}

._nameOption {
    display: flex;
    margin: 0;
    font-size: 20px;
    font-family: 'InterBold';
    color: hsla(20, 0%, 18%, 0.95);
    /* TODO: ERR-TUTORIA COMO PUEDO METER ESTO EN CSS ????? PQ SCREENSIZE CON EL MEDIA SI LO ENCONTRÉ PERO ESTA VARIABLE.... color: `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsla(20, 0%, 18%, 0.95)")}`, // https://hslpicker.com/ */
}

._navbarHome {
    display: flex;
    flex: 1.5;
    margin: 0px 20px;
    margin-bottom: 0px;
}

._options {
    display: flex;
    flex: 11;
    flex-direction: column;
    align-items: center;
    margin: 0px 20px;
}

._selector {
    width: 50vw;
    display: flex;
    justify-content: right;
    padding: 1%;
    margin-right: 3%;
    margin-bottom: 0.5%;
}

._singleOption {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    border-radius: 5px;
    padding: 15px;
    text-decoration: none;
    border: none;
    outline: none;
    margin: 1.4%;
}

._subtitleNavbar {
    margin: 0;
    font-size: 14px;
    font-family: "InterBold";
    color: hsl(228, 12%, 44%);
    /*color: TODO: ERR-TUTORIA COMO PUEDO METER ESTO EN CSS ????? `${darkModeState ? ("hsl(228, 34%, 66%)") : ("hsl(228, 12%, 44%)")}`,*/
}

._textError {
    margin: 0;
    font-size: 16px;
    color: hsl(356, 69%, 56%);
    align-self: center;
}

._textsNavbar {
    display: flex;
    flex: 8;
    flex-direction: column;
}

._titleNavbar {
    margin-bottom: 5px;
    font-size: 28px;
    font-family: 'InterBold';
    line-height: 1.4;
    color: hsl(230, 17%, 14%);
    /*color: TODO: ERR-TUTORIA COMO PUEDO METER ESTO EN CSS ????? `${darkModeState ? ("hsl(0, 0%, 100%)") : ("hsl(230, 17%, 14%)")}`,*/
}




@media screen and (max-width: 940) {
    ._containerHome {
        padding: 30px;
    }

    ._formContainer {
        width: 100vw;

    }

    ._selector {
        width: 100vw;
    }

    ._columnInputs {
        width: 100vw;
    }

    ._formTitleLogin {
        font-size: 30px;
    }

    ._navbarHome {
        margin: 0px;
        margin-bottom: 30px;
    }

    ._options {
        margin: 0px;
    }

    ._singleOption {
        width: 80vw;
        margin: 10px;
    }

    ._titleNavbar {
        margin-bottom: 10px;
    }

}